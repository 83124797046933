import { createAsyncThunk } from "@reduxjs/toolkit"
import { publicRequest, request } from "../../../utility/toolRequest"

const api = `${process.env.REACT_APP_API_URL}/api/auth`

export const handleRegister = createAsyncThunk(
    'tools-authentication/handleRegister', async (registerData) => {
        const response = await request.post(`${api}/register`, registerData)
        return response
    }
)

export const handleLogin = createAsyncThunk(
    'tools-authentication/handleLogin', async (loginData) => {
        const response = await publicRequest.post(`${api}/login`, loginData)
        return response
    }
)

export const handleLogout = createAsyncThunk(
    'tools-authentication/handleLogout', async () => {
        const response = await request.get(`${api}/logout`)
        return response
    }
)
