import { createSlice } from "@reduxjs/toolkit"
import { handleLogin, handleLogout, handleRegister } from "./authentication.thunk"

const authenticationSlice = createSlice({
    name: 'tools-authentication',
    initialState: {
       userInfo: {}
    },
    reducers: {},
    extraReducers: builder => {
        builder
        .addCase(handleRegister.fulfilled, (state, action) => {
            console.log(action.payload)
        })
        .addCase(handleLogin.fulfilled, (state, action) => {
            state.userInfo = action.payload.data.user
            localStorage.setItem('userInfo', JSON.stringify(action.payload.data.user))
        })
        .addCase(handleLogout.fulfilled, () => {
            localStorage.removeItem('userInfo')
            localStorage.removeItem('access_token')
            window.location.replace('/login')
        })
    }
})

export default authenticationSlice.reducer