import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { request } from "../../../utility/toolRequest"
const api = `${process.env.REACT_APP_API_URL}/api/tools`
export const getAllShopsBadReview = createAsyncThunk("shopBadReview/getAllShopsBadReview", async () => {
  const response = await request.get(`${api}/shop-bad-review`)
  return response
})

export const createShopBadReview = createAsyncThunk("shopBadReview/createShopBadReview", async (data) => {
  const response = await request.post(`${api}/shop-bad-review`, data)
  return response
})

export const updateShopBadReview = createAsyncThunk("shopBadReview/updateShopBadReview", async (data) => {
  const response = await request.put(`${api}/shop-bad-review/${data.id}`, data)
  return response
})

export const deleteShopBadReview = createAsyncThunk("shopBadReview/deleteShopBadReview", async (id) => {
  const response = await request.delete(`${api}/shop-bad-review/${id}`)
  return response
})

export const uploadShopBadReviewCSV = createAsyncThunk("shopBadReview/uploadShopBadReviewCSV", async (data) => {
  console.log("aaa")
  const response = await request.post(`${api}/upload-shop-bad-review-csv`, data)
  return response
})
const shopBadReviewSlice = createSlice({
  name: "shopBadReview",
  initialState: {
    shopsBadReview: [],
    gettingShop: false,
    type: "create",
    dataEdit: {
      id: "",
      shop: "",
      rating: 1
    }
  },
  reducers: {
    setDataEdit: (state, action) => {
      state.dataEdit = action.payload
      console.log(state.dataEdit)
    },
    setTypeShopsBadReview: (state, action) => {
      state.type = action.payload
    },
    setShopBadReview: (state, action) => {
      state.shopsBadReview = action.payload
    },
    deleteShopBadReviewRdc: (state, action) => {
      state.shopsBadReview = state.shopsBadReview.filter((shop) => shop?.id !== action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllShopsBadReview.fulfilled, (state, action) => {
        state.shopsBadReview = action.payload.data.data?.reverse()
        state.gettingShop = false
      })
      .addCase(getAllShopsBadReview.pending, (state) => {
        state.gettingShop = true
      })
      .addCase(createShopBadReview.fulfilled, (state, action) => {
        state.shopsBadReview.unshift(action.payload.data.data)
      })
      .addCase(updateShopBadReview.fulfilled, (state, action) => {
        state.shopsBadReview = state.shopsBadReview.map((shop) => (shop.id === action.payload.data.data.id ? action.payload.data.data : shop))
      })
  }
})

export const { deleteShopBadReviewRdc, setTypeShopsBadReview, setDataEdit} = shopBadReviewSlice.actions
export default shopBadReviewSlice.reducer
